/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import TimePickerComponent from 'ui/atoms/TimePicker/TimePicker';
import dayjs from 'dayjs';
import DatePicker from 'ui/atoms/DatePicker/DatePicker';
import Button from 'ui/atoms/Button/Button';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { ExpirationDateModalForm } from 'state/types';
import useTranslation from 'hooks/useTranslation/useTranslation';

const IconWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  width: 20rem;
`;

const ExpirationDate: React.FC = () => {
  const { translation } = useTranslation();
  const { hideModal, modalState } = useModal();
  // eslint-disable-next-line prefer-destructuring
  const contentValue: ExpirationDateModalForm = modalState.contentValue;
  const [dateStart] = useState<null | dayjs.Dayjs>(dayjs().set('h', 0).set('m', 0).set('s', 0).set('ms', 0));
  const [dateEnd, setDateEnd] = useState<null | dayjs.Dayjs>(
    contentValue.expirationDateTo
      ? dayjs(contentValue.expirationDateTo).set('h', 0).set('m', 0).set('s', 0).set('ms', 0)
      : null
  );
  const [timeStart] = useState<null | dayjs.Dayjs>(dayjs());
  const [timeEnd, setTimeEnd] = useState<null | dayjs.Dayjs>(
    contentValue.expirationDateTo ? dayjs(contentValue.expirationDateTo) : null
  );

  const handleSet = async () => {
    if (timeStart && timeEnd && dateStart && dateEnd) {
      contentValue.setExpirationDateTo(
        dayjs()
          .year(dateEnd.year())
          .month(dateEnd.month())
          .date(dateEnd.date())
          .hour(timeEnd.hour())
          .minute(timeEnd.minute())
          .utc()
          .format()
      );
      hideModal();
    }
  };

  const handleMinTime = () =>
    dateStart && dateStart.isSame(dateEnd, 'date')
      ? dayjs(dateStart).set('hour', dayjs().hour()).set('minute', dayjs().minute())
      : null;

  return (
    <Modal>
      <IconWrapper>
        <Icon name="Clock" width={48} height={48} viewBox="0 0 24 24" color="b1" id="clock-icon" />
      </IconWrapper>
      <ComponentWrapper>
        <ComponentWrapper flexDirection="column">
          <Typography color="primary" variant="title3" id="select-date-title">
            {translation.select_date}
          </Typography>
          <DatePicker
            valueStart={dateStart}
            valueEnd={dateEnd || null}
            onEndChange={setDateEnd}
            minDate={dayjs()}
            maxDate={dayjs().add(10, 'year')}
          />
        </ComponentWrapper>
        <ComponentWrapper flexDirection="column">
          <Typography color="primary" variant="title3" id="start-time-title">
            {translation.select_time}
          </Typography>
          <TimePickerComponent
            value={timeEnd}
            setValue={setTimeEnd}
            disabled={!dateStart && !dateEnd}
            minTime={handleMinTime()}
            onKeyDown={(event) => formKeyDownHandler(event, handleSet)}
            flexDirection="column"
          />
        </ComponentWrapper>
      </ComponentWrapper>
      <ButtonWrapper>
        <Button backgroundColor="transparent" textColor="b1" onClick={hideModal}>
          {translation.cancel}
        </Button>
        <Button onClick={handleSet}>Set</Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default ExpirationDate;
