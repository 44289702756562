import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const OfficeModeTooltipInfo: React.FC = () => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper flexDirection="column" width="14rem" padding="0.5rem 1rem" gap="1rem">
      <Typography variant="body4" color="lTextMedium">
        {
          translation.enabling_this_option_will_allow_a_user_with_the_correct_access_permissions_to_put_the_lock_into_office_mode_by_presenting_their_mobile_device
        }
      </Typography>
      <Typography variant="body4" color="lTextMedium">
        {translation.the_lock_will_automatically_lock_itself_outside_the_set_time_schedule}.
      </Typography>
    </ComponentWrapper>
  );
};

export default OfficeModeTooltipInfo;
