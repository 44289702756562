import React from 'react';
import styled from 'styled-components';
import Profile, { Props as ProfileProps } from 'ui/molecules/Profile/Profile';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Title from 'components/Title/Title';
import { useNavigate } from 'react-router-dom';
import useTranslation, { arrayOfSupportedLanguages } from 'hooks/useTranslation/useTranslation';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Separator from 'ui/atoms/Separator/Separator';
import Icon from 'ui/atoms/Icon/Icon';
import { colors } from 'theme/theme';
import { makeStyles } from '@material-ui/core';

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.3125rem 1.5rem;
`;

const Line = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.b3};
  height: 2.5rem;
  width: 1px;
`;

export interface Props {
  profile: ProfileProps;
  isMenuOpen: boolean;
  isMobileDeviceRegistered: boolean;
  anchorRef: React.RefObject<HTMLDivElement>;
  languageSelectValue: string;
  handleLanguageSelectOnChange: (event: SelectChangeEvent) => void;
  handleOpenMenu: () => void;
  handleMyDevicesOnClick: () => void;
  handleLogoutOnClick: () => void;
}

const TopBar: React.FC<Props> = ({
  profile,
  isMenuOpen,
  isMobileDeviceRegistered,
  anchorRef,
  handleOpenMenu,
  languageSelectValue,
  handleLanguageSelectOnChange,
  handleMyDevicesOnClick,
  handleLogoutOnClick
}) => {
  const { translation } = useTranslation();
  const navigate = useNavigate();

  const useStyles = makeStyles({
    select: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.primary} !important`
      }
    }
  });
  const classes = useStyles();

  return (
    <Wrapper id="sidebar">
      <ComponentWrapper gap="0.5rem" alignItems="center" justifyContent="center">
        <Icon name="ArrowRight" width={15} height={24} viewBox="0 0 24 24" color="primary" />{' '}
        <Title onClick={() => navigate('/')} />
      </ComponentWrapper>
      <ComponentWrapper flex="0 0 4rem" gap="1.5rem" onClick={handleOpenMenu}>
        <Line />

        <Profile name={profile.name} picture={profile.picture} hoverEffect={!!handleOpenMenu} />
        <Popper
          open={isMenuOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                position: 'fixed',
                top: '3rem',
                right: '2.75rem',
                width: '15rem',
                padding: '0.625rem 1rem',
                transformOrigin: 'right-top'
              }}
            >
              <Paper>
                <MenuList id="menu-id" autoFocusItem={isMenuOpen} aria-labelledby="composition-button">
                  <FormControl fullWidth>
                    <ComponentWrapper flexDirection="column" gap="0.25rem">
                      <Typography variant="label" color="dTextHigh" id="" language-select-label>
                        {translation.language}
                      </Typography>
                      <Select
                        id="language-select"
                        value={languageSelectValue}
                        onChange={handleLanguageSelectOnChange}
                        className={classes.select}
                      >
                        {arrayOfSupportedLanguages.map((supportedLanguage) => (
                          <MenuItem value={supportedLanguage.value}>{supportedLanguage.label}</MenuItem>
                        ))}
                      </Select>
                    </ComponentWrapper>
                  </FormControl>
                  <Separator marginTop="0.625rem" />
                  <MenuItem onClick={handleMyDevicesOnClick}>
                    <ComponentWrapper gap="1rem" height="1.625rem" margin="0.25rem 0">
                      <Icon
                        viewBox="0 0 26 26"
                        name={isMobileDeviceRegistered ? 'MobileDeviceConnected' : 'MobileDeviceDisconnected'}
                      />
                      {translation.my_device}
                    </ComponentWrapper>
                  </MenuItem>
                  <MenuItem onClick={handleLogoutOnClick}>
                    <ComponentWrapper gap="1rem" height="1.625rem">
                      <Icon name="LogOut" />
                      {translation.logout}
                    </ComponentWrapper>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ComponentWrapper>
    </Wrapper>
  );
};

export default TopBar;
