import { useState } from 'react';
import en from 'translations/en.json';
import pl from 'translations/pl.json';
import nl from 'translations/nl.json';
import fr from 'translations/fr.json';

import plLocale from 'dayjs/locale/pl';
import enLocale from 'dayjs/locale/en';
import frLocale from 'dayjs/locale/fr';
import nlLocale from 'dayjs/locale/nl';

type TranslationKey = keyof typeof en;
export type Translation = {
  [key in TranslationKey]: string;
};

export type SupportedLanguages = 'en' | 'pl' | 'nl' | 'fr';

export const arrayOfSupportedLanguages = [
  { value: 'en', label: 'English' },
  { value: 'pl', label: 'Polski' },
  { value: 'nl', label: 'Nederlands' },
  { value: 'fr', label: 'Français' }
];

const useTranslation = () => {
  const translations: { [key: string]: Translation } = {
    en,
    pl: pl as unknown as Translation,
    nl: nl as unknown as Translation,
    fr: fr as unknown as Translation
  };

  const getLocaleForDayjs = (getLanguage: () => SupportedLanguages) => {
    switch (getLanguage()) {
      case 'pl':
        return plLocale;
      case 'nl':
        return nlLocale;
      case 'fr':
        return frLocale;
      default:
      case 'en':
        return enLocale;
    }
  };

  function getLanguageFromBrowser(): SupportedLanguages | null {
    const language = navigator.language.split('-')[0] as SupportedLanguages;
    if (arrayOfSupportedLanguages.some((supportedLanguage) => supportedLanguage.value === language)) return language;
    return null;
  }

  const getPreferredLanguageFromCache = () => {
    const cachedData = localStorage.getItem('language');
    if (cachedData) {
      return JSON.parse(cachedData) as SupportedLanguages;
    }
    return null;
  };

  const getLanguage = () => getPreferredLanguageFromCache() || getLanguageFromBrowser() || 'en';

  const [language] = useState<SupportedLanguages>(getLanguage());

  const savePreferredLanguageInCache = (value: SupportedLanguages) => {
    localStorage.setItem('language', JSON.stringify(value));

    // refresh page to enable translations
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const translation = { ...translations.en, ...translations[language] };

  return { translation, getLanguage, savePreferredLanguageInCache, getLocaleForDayjs };
};

export default useTranslation;
