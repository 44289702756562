/* eslint-disable no-underscore-dangle */
import { isEqual, orderBy } from 'lodash';
import { VisitorsGroupsWithAccessGrantsState } from 'state/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { setVisitorGroupsWithAccessGrantsAction } from 'state/actions/accessGrants';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createUserGroupsPrioritySorting = (translation: Translation) => [
  translation.no_priority,
  translation.visitors,
  translation.external_groups
];

export const createSecondColumnPrioritySorting = (translation: Translation) => [translation.selected];

export const userGroupsPrioritySorting = ['No priority', 'Visitors', 'External Groups'] as const;

export interface Sorting {
  isMenuOpen: boolean;
  isAlphabeticAscending?: boolean;
  priority: string;
}

interface Props {
  visitorGroups: VisitorsGroupsWithAccessGrantsState;
  selectedIds?: string[];
}

const useSortingAccessProfilesTableVisitorGroup = ({ visitorGroups, selectedIds }: Props) => {
  const [sorting, setSorting] = useState<Sorting>({
    isMenuOpen: false,
    isAlphabeticAscending: true,
    priority: 'No priority'
  });

  const handleOpenSortingMenu = () => {
    setSorting({ ...sorting, isMenuOpen: !sorting.isMenuOpen });
  };

  const handleSortingOnChange = (priority: string, isAlphabeticAscending?: boolean) => {
    setSorting({ ...sorting, isMenuOpen: false, isAlphabeticAscending, priority });
  };
  const getSortedData = useCallback(() => {
    switch (sorting.priority) {
      case 'Selected': {
        const selectedVisitorGroups = visitorGroups.filter((group) => selectedIds?.includes(group.id));
        const unSelectedVisitorGroups = visitorGroups.filter((group) => !selectedIds?.includes(group.id));
        if (sorting.isAlphabeticAscending !== undefined) {
          return [
            ...orderBy(
              selectedVisitorGroups,
              [(group) => group.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            ),
            ...orderBy(
              unSelectedVisitorGroups,
              [(group) => group.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            )
          ];
        }
        return [...selectedVisitorGroups, ...unSelectedVisitorGroups];
      }
      case 'Visitors': {
        const visitorGroup = visitorGroups.filter((group) => group.name === 'visitors');
        const nonVisitorGroup = visitorGroups.filter((group) => group.name !== 'visitors');

        if (sorting.isAlphabeticAscending !== undefined) {
          return [
            ...orderBy(
              visitorGroup,
              [(group) => group.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            ),
            ...orderBy(
              nonVisitorGroup,
              [(group) => group.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            )
          ];
        }
        return [...visitorGroup, ...nonVisitorGroup];
      }
      case 'External Groups': {
        const externalUserGroup = visitorGroups.filter((group) => group.type === 'CO_CONTRACTORS');
        const nonExternalUserGroup = visitorGroups.filter((group) => group.type !== 'CO_CONTRACTORS');

        if (sorting.isAlphabeticAscending !== undefined) {
          return [
            ...orderBy(
              externalUserGroup,
              [(group) => group.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            ),
            ...orderBy(
              nonExternalUserGroup,
              [(group) => group.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            )
          ];
        }
        return [...externalUserGroup, ...nonExternalUserGroup];
      }
      case 'No priority':
      default:
        return orderBy(
          visitorGroups,
          [(group) => group.name.toLowerCase()],
          [sorting.isAlphabeticAscending ? 'asc' : 'desc']
        );
    }
  }, [visitorGroups, selectedIds, sorting.isAlphabeticAscending, sorting.priority]);

  useEffect(() => {
    const sortedData = getSortedData();
    if (!isEqual(sortedData, visitorGroups)) {
      setVisitorGroupsWithAccessGrantsAction(sortedData);
    }
  }, [visitorGroups, getSortedData]);

  const sortingMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOpenMenus = (event: MouseEvent) => {
      event.stopPropagation();
      if (sortingMenuRef.current && sorting.isMenuOpen && !sortingMenuRef.current.contains(event.target as Node)) {
        setSorting({
          ...sorting,
          isMenuOpen: false
        });
      }
    };

    document.addEventListener('mousedown', closeOpenMenus);
    return () => {
      document.removeEventListener('mousedown', closeOpenMenus);
    };
  }, [sorting]);

  return { sorting, handleOpenSortingMenu, handleSortingOnChange, sortingMenuRef };
};

export default useSortingAccessProfilesTableVisitorGroup;
