import React, { useEffect } from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useAccessEventsFilters from 'hooks/useAccessEventsFilters/useAccessEventsFilters';
import useExportAccessEvents from 'hooks/useExportAccessEvents/useExportAccessEvents';
import Loader from 'ui/atoms/Loader/Loader';
import useTranslation from 'hooks/useTranslation/useTranslation';

const PreparingExportAccessEvents: React.FC = () => {
  const { translation } = useTranslation();
  const { hideModal, modalState } = useModal();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { accessEventFilters } = useAccessEventsFilters();
  const {
    startDownloadingAccessEventsToFile,
    abortDownloadingAccessEventsToFile,
    isDownloadInProgress,
    isDownloadDone
  } = useExportAccessEvents({
    handleFetchError
  });

  useEffect(() => {
    if (!isDownloadInProgress)
      if (modalState.contentValue.isFilteredOptionSelected) {
        startDownloadingAccessEventsToFile(accessEventFilters.appliedFilters);
      } else startDownloadingAccessEventsToFile(null);
  }, [
    accessEventFilters.appliedFilters,
    isDownloadInProgress,
    modalState.contentValue.isFilteredOptionSelected,
    modalState.isFilteredOptionSelected,
    startDownloadingAccessEventsToFile
  ]);

  const handleAbortExportAccessEvents = () => {
    abortDownloadingAccessEventsToFile();
    hideModal();
  };

  useEffect(() => {
    if (isDownloadDone) {
      hideModal();
    }
  }, [hideModal, isDownloadDone]);

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="30rem"
        gap="1rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="Export" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="export-icon" />
        <Typography variant="title2" color="dTextHigh">
          {translation.preparing_your_export}
        </Typography>
        <Loader size={48} />
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="title4" color="dTextHigh">
              {translation.the_data_export_is_being_prepared_this_process_may_take_a_few_moments}.
            </Typography>
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper width="12rem">
          <Button
            backgroundColor="transparent"
            textColor="dTextHigh"
            onClick={handleAbortExportAccessEvents}
            id="abort-button-modal"
          >
            {translation.click_here_to_abort}
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default PreparingExportAccessEvents;
