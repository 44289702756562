import React from 'react';
import TableCell from 'ui/atoms/TableCell/TableCell';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableRow from 'ui/molecules/TableRow/TableRow';
import Table from 'ui/organisms/Table/Table';
import SortingMenu from 'ui/organisms/SortingMenu/SortingMenu';
import getLockIconProperties from 'utils/LocksIcon/LocksIcon.util';
import { Props as IconProps } from 'ui/atoms/Icon/Icon';
import { GetDoorsForTenantWithOfficeMode_getDoorsForTenant } from 'graphql/generated/GetDoorsForTenantWithOfficeMode';
import ArrayUtil from 'utils/Array/Array.util';
import useSortingAccessProfilesTableLock, {
  createLocksPrioritySorting
} from 'hooks/useSorting/useSortingAccessProfiles/Locks/useSortingAccessProfilesTableLock';
import useTranslation from 'hooks/useTranslation/useTranslation';
import {
  ARROW_DOWN_HEIGHT,
  ARROW_DOWN_WIDTH,
  ARROW_RIGHT_HEIGHT,
  ARROW_RIGHT_WIDTH,
  SecondLineText,
  Variant
} from '../shared';

export interface Props {
  id: string;
  loading: boolean;
  disabled: boolean;
  isCollapsed: boolean;
  handleCollapse: () => void;
  isSortingMenuVisible: boolean;
  variant: Variant;
  locks: GetDoorsForTenantWithOfficeMode_getDoorsForTenant[];
  filteredLocks: GetDoorsForTenantWithOfficeMode_getDoorsForTenant[];
  selectedIds: string[];
  disabledIds: string[];
  additionalTexts: SecondLineText[];
  warningTexts: SecondLineText[];
  handleClickTableRow: (locks: GetDoorsForTenantWithOfficeMode_getDoorsForTenant) => void;
}

const AccessProfilesLocks: React.FC<Props> = ({
  id,
  loading,
  disabled,
  isCollapsed,
  handleCollapse,
  isSortingMenuVisible,
  variant,
  locks,
  filteredLocks,
  selectedIds,
  disabledIds,
  additionalTexts,
  warningTexts,
  handleClickTableRow
}) => {
  const { translation } = useTranslation();
  const { sorting, handleOpenSortingMenu, handleSortingOnChange, sortingMenuRef } = useSortingAccessProfilesTableLock({
    locks,
    selectedIds,
    isSortingEnable: isSortingMenuVisible
  });

  const skeletonArray = ArrayUtil.SkeletonArray();

  const isLockSelected = (lockId: string) => selectedIds.includes(lockId);
  const isLockDisabled = (lockId: string) => disabledIds.includes(lockId);

  const getLocksWithAdditionalText = (lockId: string) => additionalTexts.find((item) => item.id === lockId)?.text;

  const getLockWithWarningText = (lockId: string) => warningTexts.find((item) => item.id === lockId)?.text;

  const getSecondLineText = (lockId: string) => getLocksWithAdditionalText(lockId) || getLockWithWarningText(lockId);

  const getFirstLineTextColor = (lockId: string) =>
    variant === 'icon' && isLockSelected(lockId) && !isLockDisabled(lockId) ? 'primary' : 'lTextHigh';

  const getIconAndSecondLineTextColor = (lockId: string) => (getLockWithWarningText(lockId) ? 'warning' : 'lTextHigh');

  const getAfterContentIconProps = (lock: GetDoorsForTenantWithOfficeMode_getDoorsForTenant): IconProps | undefined => {
    if (lock.location !== null) return { name: 'MicrosoftOutlook' };
    return undefined;
  };

  const getBeforeContentIconProps = (lock: GetDoorsForTenantWithOfficeMode_getDoorsForTenant): IconProps => ({
    ...getLockIconProperties.getLockIconProperties(lock.domSerialNumber, getIconAndSecondLineTextColor(lock.id)),
    height: 28,
    width: 28,
    viewBox: '0 0 32 32'
  });

  return (
    <Table
      height={isCollapsed ? '3rem' : 'auto'}
      flex="0 1 auto"
      id={id}
      header={
        !loading ? (
          <SortingMenu
            // @ts-ignore
            ref={sortingMenuRef}
            isOpen={isSortingMenuVisible && sorting.isMenuOpen}
            sortingName={translation.alphabetic_order}
            sortingOptions={[
              {
                isSelected: !!sorting.isAlphabeticAscending,
                onClick: () => handleSortingOnChange(sorting.priority, true),
                text: translation.ascending,
                id: 'sorting-option-ascending'
              },
              {
                isSelected: !sorting.isAlphabeticAscending,
                onClick: () => handleSortingOnChange(sorting.priority, false),
                text: translation.descending,
                id: 'sorting-option-descending'
              }
            ]}
            additionalSortingName={translation.priority}
            additionalSortingOptions={
              !loading && [
                ...createLocksPrioritySorting(translation).map((priority) => ({
                  isSelected: sorting.priority === priority,
                  onClick: () => handleSortingOnChange(priority, sorting.isAlphabeticAscending),
                  text: priority,
                  id: `sorting-option-${priority}`
                })),
                variant === 'switch' && !disabled
                  ? {
                      isSelected: sorting.priority === translation.selected,
                      onClick: () => handleSortingOnChange(translation.selected, sorting.isAlphabeticAscending),
                      text: translation.selected,
                      id: 'sorting-option-selected'
                    }
                  : undefined
              ]
            }
          >
            <TableHeaderRow
              disabled={disabled}
              iconAfterHeaderProps={
                isSortingMenuVisible
                  ? { name: 'Sorting', onClick: !disabled ? handleOpenSortingMenu : undefined }
                  : undefined
              }
              iconAfterHeaderBorder
              id="access-profiles-header-row-doors"
              iconBeforeHeaderProps={{
                name: isCollapsed ? 'ArrowRight' : 'ArrowDown',
                width: isCollapsed ? ARROW_RIGHT_WIDTH : ARROW_DOWN_WIDTH,
                height: isCollapsed ? ARROW_RIGHT_HEIGHT : ARROW_DOWN_HEIGHT,
                color: isCollapsed ? undefined : 'transparent',
                stroke: isCollapsed ? undefined : 'lTextMedium',
                viewBox: isCollapsed ? '0 0 22 45' : undefined,
                onClick: handleCollapse
              }}
            >
              <TableHeader
                headerText={translation.locks}
                superText={variant === 'switch' ? `(${selectedIds.length})` : undefined}
                id="access-profiles-header-doors"
              />
            </TableHeaderRow>
          </SortingMenu>
        ) : (
          <TableHeaderRow
            disabled={disabled}
            placeForAvatarOrSwitchOrCheckbox={variant === 'icon'}
            id="access-profiles-header-row-locks"
          >
            <TableHeader
              headerText={translation.locks}
              iconSorting="ArrowDown"
              onClick={handleOpenSortingMenu}
              id="access-profiles-header-locks"
            />
          </TableHeaderRow>
        )
      }
    >
      {loading
        ? skeletonArray.map((skeletonItem) => (
            <TableRow
              beforeSwitchSkeleton={variant === 'switch'}
              avatarSkeleton={variant === 'icon'}
              selected={false}
              disabled
              id="access-profiles-row-locks"
              key={`skeletonTableRow-${skeletonItem.id}`}
            >
              <TableCell isLoading firstLineText="" />
            </TableRow>
          ))
        : !isCollapsed &&
          filteredLocks.map((lock, index) => (
            <TableRow
              hoverEffect
              afterSwitchProps={
                variant !== 'switch'
                  ? undefined
                  : {
                      checked: isLockSelected(lock.id),
                      disabled: isLockDisabled(lock.id),
                      onClick: (event) => event.stopPropagation()
                    }
              }
              marginAfterContent={variant === 'switch'}
              beforeContentIconProps={getBeforeContentIconProps(lock)}
              selected={variant === 'icon' ? isLockSelected(lock.id) : false}
              disabled={(variant === 'switch' && disabled) || isLockDisabled(lock.id)}
              onClick={!disabled && !isLockDisabled(lock.id) ? () => handleClickTableRow(lock) : undefined}
              afterContentIconProps={getAfterContentIconProps(lock)}
              id={`access-profiles-row-locks-${index}`}
              key={lock.id}
            >
              <TableCell
                firstLineText={lock.name}
                firstLineColor={getFirstLineTextColor(lock.id)}
                secondLineText={getSecondLineText(lock.id)}
                secondLineColor={getIconAndSecondLineTextColor(lock.id)}
                key={lock.id}
                isRowWithIconAndSwitch
              />
            </TableRow>
          ))}
    </Table>
  );
};

export default AccessProfilesLocks;
