import { isEqual, orderBy } from 'lodash';
import { setDoorsAction } from 'state/actions/doors';
import { DoorsState, LocationsWithDoorsState } from 'state/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { setLocationsWithDoorsAction } from 'state/actions/locationsWithDoors';
import { Translation } from 'hooks/useTranslation/useTranslation';

export const createLocksPrioritySorting = (translation: Translation) => [
  translation.no_priority,
  translation.reservable
];

export const createSecondColumnPrioritySorting = (translation: Translation) => [translation.selected];

export interface Sorting {
  isMenuOpen: boolean;
  isAlphabeticAscending?: boolean;
  priority: string;
}

interface Props {
  doors: DoorsState;
  lockGroups: LocationsWithDoorsState;
  selectedDoorsIds: string[] | null;
  selectedLocationsIds: string[] | null;
}

const useSortingAccessProfilesTableLockGroupsAndLocks = ({
  doors,
  lockGroups,
  selectedDoorsIds,
  selectedLocationsIds
}: Props) => {
  const [sorting, setSorting] = useState<Sorting>({
    isMenuOpen: false,
    isAlphabeticAscending: true,
    priority: 'No priority'
  });

  const handleOpenSortingMenu = () => {
    setSorting({ ...sorting, isMenuOpen: !sorting.isMenuOpen });
  };

  const handleSortingOnChange = (priority: string, isAlphabeticAscending?: boolean) => {
    setSorting({ ...sorting, isMenuOpen: false, isAlphabeticAscending, priority });
  };

  const getSortedDoors = useCallback(() => {
    switch (sorting.priority) {
      case 'Reservable':
        return orderBy(
          doors,
          ['location', sorting.isAlphabeticAscending !== undefined && 'name'],
          ['asc', sorting.isAlphabeticAscending ? 'asc' : 'desc']
        );

      case 'Selected': {
        const selectedDoors = doors.filter((door) => selectedDoorsIds?.includes(door.id));
        const unSelectedDoors = doors.filter((door) => !selectedDoorsIds?.includes(door.id));
        if (sorting.isAlphabeticAscending !== undefined) {
          return [
            ...orderBy(
              selectedDoors,
              [(door) => door.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            ),
            ...orderBy(
              unSelectedDoors,
              [(door) => door.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            )
          ];
        }
        return [...selectedDoors, ...unSelectedDoors];
      }
      case 'No priority':
      default:
        return orderBy(doors, [(door) => door.name.toLowerCase()], [sorting.isAlphabeticAscending ? 'asc' : 'desc']);
    }
  }, [doors, selectedDoorsIds, sorting.isAlphabeticAscending, sorting.priority]);

  const getSortedLocations = useCallback(() => {
    switch (sorting.priority) {
      case 'Selected': {
        const selectedLocations = lockGroups.filter((location) => selectedLocationsIds?.includes(location.id));
        const unSelectedLocations = lockGroups.filter((location) => !selectedLocationsIds?.includes(location.id));
        if (sorting.isAlphabeticAscending !== undefined) {
          return [
            ...orderBy(
              selectedLocations,
              [(lockGroup) => lockGroup.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            ),
            ...orderBy(
              unSelectedLocations,
              [(lockGroup) => lockGroup.name.toLowerCase()],
              [sorting.isAlphabeticAscending ? 'asc' : 'desc']
            )
          ];
        }
        return [...selectedLocations, ...unSelectedLocations];
      }
      case 'No priority':
      default:
        return orderBy(
          lockGroups,
          [(lockGroup) => lockGroup.name.toLowerCase()],
          [sorting.isAlphabeticAscending ? 'asc' : 'desc']
        );
    }
  }, [lockGroups, selectedLocationsIds, sorting.isAlphabeticAscending, sorting.priority]);

  useEffect(() => {
    const sortedDoors = getSortedDoors();
    if (!isEqual(sortedDoors, doors)) {
      setDoorsAction(sortedDoors);
    }
  }, [doors, getSortedDoors]);

  useEffect(() => {
    const sortedLocations = getSortedLocations();
    if (!isEqual(sortedLocations, lockGroups)) {
      setLocationsWithDoorsAction(sortedLocations);
    }
  }, [getSortedLocations, lockGroups]);

  const sortingMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOpenMenus = (event: MouseEvent) => {
      event.stopPropagation();
      if (sortingMenuRef.current && sorting.isMenuOpen && !sortingMenuRef.current.contains(event.target as Node)) {
        setSorting({
          ...sorting,
          isMenuOpen: false
        });
      }
    };

    document.addEventListener('mousedown', closeOpenMenus);
    return () => {
      document.removeEventListener('mousedown', closeOpenMenus);
    };
  }, [sorting]);

  return { sorting, handleOpenSortingMenu, handleSortingOnChange, sortingMenuRef };
};

export default useSortingAccessProfilesTableLockGroupsAndLocks;
