import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import Icon from 'ui/atoms/Icon/Icon';
import Separator from 'ui/atoms/Separator/Separator';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const AccessProfilesTooltipInfo: React.FC = () => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper flexDirection="column" width="33.875rem" padding="0.5rem 1rem" gap="1rem">
      <Typography variant="title4" color="lTextHigh">
        <b>{translation.information}</b>
      </Typography>
      <ComponentWrapper flexDirection="row" gap="0.5rem" justifyContent="left" alignItems="center">
        <Icon name="MicrosoftOutlook" color="lTextHigh" />
        <Typography variant="body4" color="lTextHigh">
          <b>{translation.doors_with_an_outlook_room}</b>
        </Typography>
      </ComponentWrapper>
      <ComponentWrapper flexDirection="column" gap="2rem">
        <ComponentWrapper flexDirection="column">
          <Typography variant="body4" color="lTextHigh">
            {
              translation.Locks_with_this_icon_are_assigned_to_a_room_that_is_connected_with_Microsoft_Outlook_These_rooms_can_be_reserved_and_accessed_by_any_user_regardless_of_access_profile
            }
          </Typography>
        </ComponentWrapper>
        <ComponentWrapper flexDirection="column">
          <Typography variant="body4" color="lTextHigh">
            {translation.users_assigned_to_these_locks_can_access_the_rooms_without_needing_an_outlook}
          </Typography>
        </ComponentWrapper>
      </ComponentWrapper>
      <Separator color="b4" />
      <ComponentWrapper flexDirection="row" gap="0.5rem" justifyContent="left" alignItems="center">
        <Icon name="TodayOutlined" color="lTextHigh" />
        <Typography variant="body4" color="lTextHigh">
          <b>{translation.atlas_reservation_required}</b>
        </Typography>
      </ComponentWrapper>
      <ComponentWrapper flexDirection="column">
        <ComponentWrapper flexDirection="column">
          <Typography variant="body4" color="lTextHigh">
            {translation.user_groups_with_this_icon_are_required_to_make_a_reservation_using_the_atlas_portal}
          </Typography>
        </ComponentWrapper>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};

export default AccessProfilesTooltipInfo;
