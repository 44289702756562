import { DayState } from 'components/Modal/variants/EditSchedule';
import React, { MouseEventHandler } from 'react';
import Switch from 'ui/atoms/Switch/Switch';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

type Variant = 'input' | 'label';

export interface Props {
  variant: Variant;
  day: DayState;
  switchChecked?: boolean;
  handleSwitchOnChange: MouseEventHandler<HTMLInputElement>;
  inputFromValue?: string;
  setDay: React.Dispatch<React.SetStateAction<DayState>>;
  handleInputTimeOnChange: (
    setHandler: React.Dispatch<React.SetStateAction<DayState>>,
    day: DayState,
    valueFrom?: string,
    valueTo?: string
  ) => void;
  inputToValue?: string;
  inputValidationFailed?: boolean;
  disabledInputs: boolean;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const EditScheduleRow: React.FC<Props> = ({
  variant,
  day,
  switchChecked = false,
  handleSwitchOnChange,
  inputFromValue,
  handleInputTimeOnChange,
  setDay,
  inputToValue,
  inputValidationFailed,
  disabledInputs,
  onKeyDown
}) => (
  <ComponentWrapper
    flexDirection="row"
    justifyContent="center"
    alignItems="center"
    width="100%"
    gap="1.5rem"
    margin="0.5rem 0"
  >
    <ComponentWrapper flexDirection="row" justifyContent="space-between" width="13rem" alignItems="center">
      <Typography variant="body1" color="dTextHigh">
        {day.displayName}
      </Typography>
      <Switch
        variant="dark"
        checked={switchChecked}
        onClick={handleSwitchOnChange}
        id={`edit-schedule-switch-${day.weekDays?.dayName}`}
      />
    </ComponentWrapper>
    <ComponentWrapper flexDirection="row" gap="0.625rem" alignItems="center">
      <ComponentWrapper width="10.25rem" justifyContent="flex-start" alignItems="center">
        {variant === 'input' ? (
          <Input
            type="time"
            value={inputFromValue}
            onChange={(event) => handleInputTimeOnChange(setDay, day, event.target.value, undefined)}
            disabled={!switchChecked || disabledInputs}
            failed={inputValidationFailed}
            hideValidationText
            hideValidationEmptySpace
            hideMarginTop
            id={`edit-schedule-input-from-${day.weekDays?.dayName}`}
            required
            onKeyDown={onKeyDown}
          />
        ) : (
          <ComponentWrapper margin="0.375rem 0.625rem 0.4375rem 0.625rem">
            <Typography
              variant="body1"
              color={switchChecked ? 'dTextHigh' : 'dTextLow'}
              id={`edit-schedule-label-from-${day.weekDays?.dayName}`}
            >
              {inputFromValue}
            </Typography>
          </ComponentWrapper>
        )}
      </ComponentWrapper>
      <Typography variant="body1" color="dTextHigh">
        to
      </Typography>
      <ComponentWrapper width="10.25rem" justifyContent="flex-start" alignItems="center">
        {variant === 'input' ? (
          <Input
            type="time"
            value={inputToValue}
            onChange={(event) => handleInputTimeOnChange(setDay, day, undefined, event.target.value)}
            disabled={!switchChecked || disabledInputs}
            failed={inputValidationFailed}
            hideValidationText
            hideValidationEmptySpace
            hideMarginTop
            id={`edit-schedule-input-to-${day.weekDays?.dayName}`}
            required
            onKeyDown={onKeyDown}
          />
        ) : (
          <ComponentWrapper margin="0.375rem 0.625rem 0.4375rem 0.625rem">
            <Typography
              variant="body1"
              color={switchChecked ? 'dTextHigh' : 'dTextLow'}
              id={`edit-schedule-label-to-${day.weekDays?.dayName}`}
            >
              {inputToValue}
            </Typography>
          </ComponentWrapper>
        )}
      </ComponentWrapper>
    </ComponentWrapper>
  </ComponentWrapper>
);

export default EditScheduleRow;
