import useTranslation from 'hooks/useTranslation/useTranslation';
import React from 'react';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  handleOnButtonClick?: () => void;
}

const NoExternalUsersWithButton: React.FC<Props> = ({ handleOnButtonClick }) => {
  const { translation } = useTranslation();
  return (
    <ComponentWrapper
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="1.5rem"
      width="20.125rem"
      height="14rem"
    >
      <Icon name="UserCoContractors" width={56} height={56} viewBox="0 0 24 24" />
      <Typography variant="title4" id="no-external-group-member-label">
        {translation.no_users}
      </Typography>
      <ComponentWrapper flexDirection="column">
        <Typography variant="body4">{translation.there_are_no_users_assigned}.</Typography>
        <Typography variant="body4">{translation.click_add_to_add_a_new_user}</Typography>
      </ComponentWrapper>
      <ComponentWrapper width="11rem">
        <Button onClick={handleOnButtonClick} id="add-external-group-member-button">
          {translation.add}
        </Button>
      </ComponentWrapper>
    </ComponentWrapper>
  );
};
export default NoExternalUsersWithButton;
